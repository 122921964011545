export const DEFAULT_SELECT_FILTER_OPTIONS = [
  { value: '', label: '(Todos)' },
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

export const DEFAULT_SELECT_FILTER_OPTIONS_WITH_NULL = [
  { value: '', label: 'Nulo' },
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
]

export const TABLE_SX = {
  fontSize: '1.4rem',
  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
}

export const asaasConfirmationScreenTypes = ['block', 'release']

export const asaasSpreadSheetTypes = [
  { label: 'Planilha de fechamento Imobiliária', value: 'closing_spreadsheet_intermediary' },
  { label: 'Planilha de fechamento - Valor Refera', value: 'closing_spreadsheet_refera' },
]
